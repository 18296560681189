// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// apiUrl: (currenttenant != '0') ? `http://35.154.99.45/tatrack-backend-api/public/api/${currenttenant}/` : 'http://35.154.99.45/tatrack-backend-api/public/api/',
import { StorageService } from "src/app/core/common/storage.service";
var currenttenant = StorageService.getTenant();
/* if(currenttenant == null){
  // logout
  localStorage.clear();
}    */

export const environment = {
  uat: true,
  production: true,
  apiUrl: (currenttenant && currenttenant != null && currenttenant != "0") ? `https://uatapp.tatracker.cloud/api/api/${currenttenant}/` : 'https://uatapp.tatracker.cloud/api/api/',
  //apiUrl: (currenttenant && currenttenant != null)? `http://127.0.0.1:8000/api/${currenttenant}/` : 'http://127.0.0.1:8000/tatrack-backend-api/public/api/main/',
  apiUrl2: 'https://uatapp.tatracker.cloud/api/api/',
  // apiUrl2: 'http://127.0.0.1:8000/api/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


// https://uatapp.tatracker.cloud/api/api/
