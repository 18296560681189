import { Injectable } from '@angular/core';
import { Subject, timer, fromEvent, BehaviorSubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from "@angular/router";
import { AuthService } from '../auth/auth.service'; // Import AuthService

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private idleTime = 15 * 60 * 1000 // 15 min
  private countdownTime = 10; // 10 seconds

  private idle$ = new Subject<void>();
  private countdown$ = new BehaviorSubject<number>(this.countdownTime);
  private logout$ = new Subject<void>();

  private idleTimerSub?: Subscription;
  private countdownTimerSub?: Subscription;

  constructor(private router: Router, private authService: AuthService) {
    this.startWatching();
  }

  private startWatching() {
    this.resetTimers();

    // Listen for user activity and reset timers
    fromEvent(document, 'mousemove').pipe(
      tap(() => this.resetTimers())
    ).subscribe();

    fromEvent(document, 'keydown').pipe(
      tap(() => this.resetTimers())
    ).subscribe();

    // Handle logout when triggered
    this.logout$.subscribe(() => {
      this.stopTimers(); // Stop idle and countdown timers
      console.log('User logged out due to inactivity');
      localStorage.clear();
      this.router.navigate(['']);
      window.location.reload();
    });
  }

  private resetTimers() {
    this.stopTimers();

    // Start the idle timer
    // this.idleTimerSub = timer(this.idleTime).pipe(
    //   tap(() => this.triggerIdle())
    // ).subscribe();

    // Start the idle timer only if the user is logged in
    if (this.authService.getAccessToken()) {
      this.idleTimerSub = timer(this.idleTime).pipe(
        tap(() => this.triggerIdle())
      ).subscribe();
    }
  }

  private stopTimers() {
    if (this.idleTimerSub) {
      this.idleTimerSub.unsubscribe();
      this.idleTimerSub = undefined;
    }
    if (this.countdownTimerSub) {
      this.countdownTimerSub.unsubscribe();
      this.countdownTimerSub = undefined;
    }
  }

  private triggerIdle() {
    this.idle$.next(); // Notify that the user is idle

    // Start the countdown timer
    // this.countdownTimerSub = timer(0, 1000).pipe(
    //   tap(tick => {
    //     const remaining = this.countdownTime - tick;
    //     console.log(remaining)
    //     this.countdown$.next(remaining);
    //     if (remaining <= 0) {
    //       this.countdownTimerSub?.unsubscribe();
    //       this.logout$.next(); // Trigger logout when countdown reaches 0
    //     }
    //   })
    // ).subscribe();

    if (this.authService.getAccessToken()) {
      this.countdownTimerSub = timer(0, 1000).pipe(
        tap(tick => {
          const remaining = this.countdownTime - tick;
          console.log(remaining)
          this.countdown$.next(remaining);
          if (remaining <= 0) {
            this.countdownTimerSub?.unsubscribe();
            this.logout$.next(); // Trigger logout when countdown reaches 0
          }
        })
      ).subscribe();
    }
  }

  getIdleEvents() {
    return this.idle$.asObservable();
  }

  getCountdown() {
    return this.countdown$.asObservable();
  }


}
